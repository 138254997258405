<template>
	<layout-main>
		<div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<div class="subheader-left">
						<h3>{{ $t('labels.import') }}</h3>
						<span class="separator-line"></span>
						<div class="breadcrumbs">
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
							<span class="separator-dot"></span>
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.import') }}</router-link>
						</div>
					</div>

					<div class="subheader-right">
						<v-guard :permissions="['section-access-police-import']">
							<button @click="importTickets($refs.vuetable.selectedTo)" class="mr-3 btn btn-wide font-bold btn-success">{{ $t('labels.do-import') }}</button>
						</v-guard>
						<v-guard :permissions="['section-access-police-import']">
							<button @click="deletePoliceImportTicket($refs.vuetable.selectedTo)" class="mr-3 btn btn-wide font-bold btn-danger">{{ $t('labels.delete') }}</button>
						</v-guard>
						<v-guard :permissions="['section-access-police-import']">
							<router-link class="btn btn-wide font-bold btn-primary" :to="{ name: 'police-import.imports' }">
								{{ $t('labels.police-files') }} <i class="fas fa-arrow-right"></i
							></router-link>
						</v-guard>
					</div>
				</div>
			</div>
			<!-- Header -->

			<div class="row">
				<div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
					<div class="widget">
						<div class="widget-header">
							<div class="widget-header-label">
								<h3>{{ $t('labels.import-management') }}</h3>
							</div>
							<div class="row">
								<div class=" form-inline">
									<div class="col-4">
										<div class="search-action">
											<input type="text" class="w-100" style="outline: none;" placeholder="Ärende#.." v-on:input="onFilterSetTicketId" v-model="ticketId" />
										</div>
									</div>
									<div class="col-8">
										<v-select
											style="min-width: 170px;"
											:options="statuses"
											transition="none"
											:filterable="false"
											v-model="status"
											:clearable="false"
											label="label"
											@option:selected="onFilterSetStatus"
											><span slot="no-options">{{ $t('labels.no-values') }}</span>

											<template #open-indicator="{ attributes }">
												<span span v-bind="attributes"><i class="fas fa-filter"></i></span>
											</template>
										</v-select>
									</div>
								</div>
							</div>
						</div>
						<div class="widget-body no-padding">
							<vuetable
								ref="vuetable"
								:fields="fields"
								data-path="data"
								pagination-path=""
								:http-fetch="apiEngine"
								@vuetable:load-error="showError('Något gick fel')"
								:no-data-template="this.$t('labels.no-tickets-in-minerva-found')"
								:sort-order="sortOrder"
								:per-page="10"
								@vuetable:pagination-data="onPaginationData"
								class="clickable-vuetable"
								@vuetable:cell-clicked="cellClicked"
								:appendParams="currentFilters"
								:show-sort-icons="true"
								:css="css.table"
							>
								<template slot="statuses" slot-scope="props">
									<div class="d-flex justify-content-center">
										<i
											v-if="hasErrors(props.rowData)"
											class="fas fa-exclamation-circle text-warning"
											v-tooltip.bottom="{ content: hasErrors(props.rowData) }"
										></i>
										<i v-else-if="isRejected(props.rowData)" class="fas fa-times text-success"></i>
										<i v-else class="far fa-check-circle text-success"></i>
									</div>
								</template>
								<template slot="actions" slot-scope="props">
									<div class="table-button-container">
										<button
											class="btn mr-3"
											:class="!isImportDisabled(props.rowData) && !isRejected(props.rowData) && !hasErrors(props.rowData) ? 'btn-success' : 'btn-secondary'"
											:disabled="isImportDisabled(props.rowData) || isRejected(props.rowData) || hasErrors(props.rowData)"
											@click="importTickets([props.rowData.id])"
										>
											{{ $t('labels.do-import') }}
										</button>
										<button
											class="btn"
											:class="'btn-danger'"
											@click="deletePoliceImportTicket([props.rowData.id])"
										>
											{{ $t('labels.delete') }}
										</button>
									</div>
								</template>
								<template slot="is_imported" slot-scope="props">
									<div class="d-flex justify-content-center">
										<i v-if="isImported(props.rowData)" class="far fa-check-circle text-success"></i>
									</div>
								</template>
							</vuetable>
							<vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Vuetable from 'vuetable-2';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import SelectField from '@/components/ticket/fields/SelectField';
import BojAPI from '@/api/boj';
import moment from 'moment';

export default {
	name: 'police-import-overview',
	components: {
		LayoutMain,
		Vuetable,
		VuetablePagination,
		SelectField,
		moment: moment,
	},

	data() {
		return {
			currentFilters: {
				status: 'unhandled',
				ticket_id: '',
			},
			statuses: [
				{
					label: 'Alla',
					query: {
						status: 'all',
					},
				},
				{
					label: 'Behandlade',
					query: {
						status: 'handled',
					},
				},
				{
					label: 'Obehandlade',
					query: {
						status: 'unhandled',
					},
				},
				{
					label: 'Fel',
					query: {
						status: 'errors',
					},
				},
				{
					label: 'Importerade',
					query: {
						status: 'imported',
					},
				},
			],
			status: {
				label: 'Obehandlade',
				query: {
					status: 'unhandled',
				},
			},
			ticketId: '',
			css: {
				table: {
					ascendingIcon: 'fas fa-arrow-up',
					descendingIcon: 'fas fa-arrow-down',
				},
			},
			dataCount: 0,
			fields: [
				'__checkbox',
				{
					name: 'id',
					title: 'IMPORT-ID',
					sortField: 'id',
				},
				{
					name: 'created_at',
					title: this.$t('labels.created'),
					sortField: 'created_at',
					callback(value) {
						return moment(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{
					name: 'updated_at',
					title: this.$t('labels.last-modified'),
					sortField: 'updated_at',
					callback(value) {
						return moment(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{
					name: '__slot:is_imported',
					title: this.$t('labels.imported'),
				},
				{
					name: '__slot:statuses',
					title: this.$t('labels.status'),
					titleClass: 'text-center',
				},
				{
					name: '__slot:actions',
					title: 'Utför',
					titleClass: 'text-left',
				},
			],
			sortOrder: [
				{
					field: 'id',
					direction: 'desc',
				},
			],
		};
	},
	mounted() {},
	computed: {},
	methods: {
		isImportDisabled(object) {
			return object.is_imported == 1;
		},
		isImported(object) {
			return object.is_imported == 1;
		},
		isRejected(object) {
			return object.is_rejected == 1;
		},
		cellClicked(policeImportTicket) {
			this.editTicket(policeImportTicket.id);
		},
		hasErrors(object) {
			let errors = [...object.errors];
			let messages = [];
			errors.forEach((element) => {
				messages.push(this.$to(element, 'message'));
			});
			
			if(object.has_duplicates) {
				messages.push('Innehåller dubletter');
			}
		
			return messages.length > 0 ? messages.join(', ') : null;
		},
		apiEngine(apiUrl, httpOptions) {
			return BojAPI.getPoliceTickets(httpOptions);
		},
		onFilterSetTicketId() {
			this.currentFilters.status = '';
			this.currentFilters.ticket_id = this.ticketId;
			this.$nextTick(() => this.$refs.vuetable.refresh());
		},
		onFilterSetStatus(data) {
			this.currentFilters.status = data.query.status;
			this.$nextTick(() => this.$refs.vuetable.refresh());
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		importTickets(ids) {
			BojAPI.createTicketsFromPoliceTickets(ids).then((response) => {
				this.$refs.vuetable
					.refresh()
					.then(() => {
						console.log(response);
						if (response.data.error_count) {
							this.$notify({
								group: 'foo',
								text: 'En eller flera polisärenden kunde inte importeras',
								type: 'warning',
								duration: 3000,
							});
						} else {
							this.$notify({
								group: 'foo',
								text: 'Polisärendet har importerats.',
								type: 'success',
								duration: 3000,
							});
						}
					})
					.catch((error) => {
						this.showError(this.$t('labels.unknown-error'));
					});
			});
		},
		deletePoliceImportTicket(ids) {
			BojAPI.deletePoliceImportTicket(ids).then((response) => {
				this.$refs.vuetable.refresh().then(() => {
					this.$notify({
						group: 'foo',
						text: 'Polisärendet är raderad.',
						type: 'success',
						duration: 3000,
					});
				});
			});
		},
		editTicket(id) {
			JSON.stringify(id);
			this.$router.push({ name: 'police-import-ticket', params: { id: id } });
		},
		
		showError(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'warning',
				duration: 3000,
			});
		},
	},
};
</script>
