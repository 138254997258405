<template>
	<layout-main>
		<div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<div class="subheader-left">
						<h3>Importer</h3>
						<span class="separator-line"></span>
						<div class="breadcrumbs">
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
							<span class="separator-dot"></span>
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.import') }}</router-link>
							<span class="separator-dot"></span>
							<router-link :to="{ name: 'police-import.imports' }" class="breadcrumbs-link"> {{ $t('labels.police-files') }}</router-link>
						</div>
					</div>

					<div class="subheader-right">
						<!-- <v-guard :permissions="['tickets-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> Exportera</template>

                <b-dropdown-group id="dropdown-group-1" header="Format">
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'excel')">Excel
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'csv')">CSV
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
              <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> Vänta
              </button>
            </v-guard> -->
						<v-guard :permissions="['section-access-police-import']">
							<label v-if="!isUploading" class="btn btn-wide font-bold btn-primary mr-3">
								<input type="file" style="display: none" ref="file" @change="onFilePicked" />
								{{ $t('labels.download-police-file') }}
								<i class="ml-2 fas fa-upload"></i>
							</label>
							<button v-if="isUploading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.wait') }}</button>

							<button @click="updateImportFileList()" class="btn btn-wide font-bold btn-primary">
								<i class="fas fa-sync" :class="{ 'fa-spin': isUpdating }"></i> {{ $t('labels.update-the-list') }}
							</button>
						</v-guard>
					</div>
				</div>
			</div>
			<!-- Header -->

			<div class="row">
				<div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
					<div class="widget">
						<div class="widget-body no-padding">
							<vuetable
								ref="vuetable"
								:fields="fields"
								data-path=""
								pagination-path=""
								:http-fetch="apiEngine"
								@vuetable:load-error="error"
								no-data-template="Inga importer hittades"
								:sort-order="sortOrder"
								:data-total="dataCount"
							>
								<template slot="actions" slot-scope="props">
									<div class="table-button-container">
										<button class="btn mr-3" :class="importNotRunned(props) ? 'btn-success' :  'btn-secondary'" :disabled="!importNotRunned(props)" @click="runImport(props)"><i class="fas fa-sync"></i> {{ $t('labels.run') }}</button>
										<button class="btn" :class="'btn-danger'" @click="deleteFile(props)">{{ $t('labels.delete') }}</button>
									</div>
								</template>

								<template slot="statuses" slot-scope="props">
									<div class="d-flex justify-content-center">
										<i v-if="props.rowData.errors == 1" class="text-danger fas fa-exclamation-triangle"></i>
										<i v-else-if="importNotRunned(props)"
											class="fas fa-exclamation-circle text-warning"
											v-tooltip.bottom="{ content: 'Importen är inte körd ännu för denna filen.' }"
										></i>

										<i v-else-if="props.rowData.completed == 1" class="far fa-check-circle text-success"></i>
									</div>
								</template>
							</vuetable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Vuetable from 'vuetable-2';
import BojAPI from '@/api/boj';
import axios from 'axios';


export default {
	name: 'police-import-overview',
	components: {
		LayoutMain,
		Vuetable,
	},

	data() {
		return {
			isUploading: false,
			isUpdating: false,
			isImportRunning: false,
			excelFile: null,
			dataCount: 0,
			fields: [
				{
					name: 'id',
					title: 'ID',
				},
				{
					name: 'created_at_formatted',
					title: 'Skapad',
				},
				{
					name: 'updated_at_formatted',
					title: 'Uppdaterad',
				},
				{
					name: 'filename',
					title: 'Fil',
				},
				{
					name: '__slot:statuses',
					title: 'Status',
					titleClass: 'text-center',
				},
				{
					name: '__slot:actions',
					title: 'Utför',
					titleClass: 'text-center',
				},
			],
			sortOrder: [
				{
					field: 'created_at_formatted',
					direction: 'asc',
				},
			],
		};
	},
	computed: {
		
	},
	methods: {
		apiEngine(apiUrl, httpOptions) {
			const data = BojAPI.getPoliceFiles(httpOptions).then((r) => {
				return r.data;
			});

			return data;
		},
		error() {
			this.$notify({
				group: 'foo',
				text: 'Nått gick fel vid inläsningen av importfilerna',
				type: 'warning',
				duration: 3000,
			});
		},
		onFilePicked(event) {
			const files = event.target.files;
			this.excelFile = files[0];
			this.submitFile();
		},

		submitFile() {
			this.isUploading = true;
			const formData = new FormData();

			formData.append('excel', this.excelFile);

			BojAPI.uploadPoliceFile(formData)
				.then((response) => {
					this.$notify({
						group: 'foo',
						text: 'Filen har laddats upp.',
						type: 'success',
						duration: 3000,
					});

					this.$refs.vuetable.refresh();
				})
				.catch((e) => {
					if (e.response.status == 401) {
						this.$notify({
							group: 'foo',
							text: 'Rättigheter för att ladda upp en fil saknas, kontakta minerva.',
							type: 'warning',
							duration: 3000,
						});

						return;
					}

					if (e.response.status == 422) {
						this.$notify({
							group: 'foo',
							text: 'Fel filtyp. Filen måste vara en excelfil.',
							type: 'warning',
							duration: 3000,
						});

						return;
					}

					this.$notify({
						group: 'foo',
						text: 'Något gick fel, det gick inte att ladda upp filen.',
						type: 'warning',
						duration: 3000,
					});
				})
				.finally(() => {
					this.isUploading = false;
				});
		},
		deleteFile(props) {
			this.$confirm({
				message: 'Är du säker på att du vill ta bort polis importen?',
				button: {
					yes: 'Radera',
					no: 'Avbryt',
				},
				callback: (confirm) => {
					if (confirm) {
						BojAPI.deletePoliceFile(props.rowData.id)
							.then((response) => {
								this.$refs.vuetable.refresh().then(() => {
									this.$notify({
										group: 'foo',
										text: 'Importfilen är raderad.',
										type: 'success',
										duration: 3000,
									});
								});
							})
							.catch((e) => {
								this.$notify({
									group: 'foo',
									text: 'Radering av importfilen misslyckades.',
									type: 'warning',
									duration: 3000,
								});
							});
					}
				},
			});
		},

		runImport(props) {
			this.isImportRunning = true;
			BojAPI.importPoliceFile(props.rowData.id)
				.then((response) => {
					this.isImportRunning = false;
					this.$refs.vuetable.refresh();
					this.$notify({
						group: 'foo',
						text: 'Importen har körts',
						type: 'success',
						duration: 3000,
					});
				})
				.catch((e) => {
					this.$notify({
						group: 'foo',
						text: 'Något gick fel när importen kördes',
						type: 'warning',
						duration: 3000,
					});
				});
		},

		updateImportFileList() {
			this.isUpdating = true;
			console.log('updateImportFileList');
			BojAPI.getPoliceFiles().then(() => {
				this.isUpdating = false;
				this.$refs.vuetable.refresh();
			});
		},

		containErrors(props) {
			return props.rowData.errors == 1
		},

		importNotRunned(props) {
			return props.rowData.completed == 0
		},
	},
};
</script>
