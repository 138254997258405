<template>
  <reactive-base
      v-if="user"
      app="witness_index"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <export-people :filter="exportPeopleFilter" @beginTicketExport="exportResults" @closed="closeExportPeople"
                    v-if="exportPeopleModal"></export-people>
    <export-witness @beginWitnessExport="exportResults" @closed="closeExportWitness" :exportEstimate="exportEstimate"
      v-if="exportEstimate">
    </export-witness>

    <state-provider v-if="stats">
      <div class="col-12" :includeKeys="['value', 'hits', 'aggregations', 'error']" slot-scope="{ searchState }">

         <!-- <pre>{{searchState.userSubFilter.value}}</pre> -->
        <div class="row justify-content-between">

          <div class="col-md-7" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>
                    Information
                  </h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'DataControllerSensor', 'cases', $t('labels.higher')) }}
                    </div>
                    <div class="label" style="color: #D01706; background-color: rgba(208, 23, 6, 0.1);">
                      Antal mål
                    </div>
                  </div>

                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'testimonyTypeFilter', 'testimony_type.raw', 'Brottmål') }}
                    </div>
                    <div class="label" style="color: rgb(0, 142, 102); background-color: rgba(0, 142, 102, 0.1);">
                      Antal brottmål
                    </div>
                  </div>

                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'DataControllerSensor', 'external_case', 'total') }}
                    </div>
                    <div class="label" style="color: rgb(134, 193, 0); background-color: rgba(134, 193, 0, 0.1);">
                      Antal externa mål
                    </div>
                  </div>

                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'testimonyTypeFilter', 'testimony_type.raw', 'Civilmål') }}
                    </div>
                    <div class="label" style="color: rgb(93, 93, 93); background-color: rgba(93, 93, 93, 0.1);">
                      Antal civilmål
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>



          <div class="col-md-2" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>Personer</h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">

                      {{ getBucketCount(searchState, 'DataControllerSensor', 'persons', 'total')}}
                    </div>
                    <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                      Antal personer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </state-provider>

    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>
              Mål
            </h3>
          </div>

          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
							<span v-for="facet in getFacetsByLevel(1)" :key="facet.id">
								<div
                    :class="{
										'filter-action-sm': facet.small,
										'filter-action': facet.type === 'list',
										'search-action': facet.type === 'search',
									}"
                    class="ml-3"
                    v-if="checkFacetPermission(facet.permission)"
                >
									<multi-dropdown-list
                      v-if="facet.type === 'list'"
                      :placeholder="facet.placeholder"
                      :componentId="facet.id"
                      :URLParams="true"
                      sortBy="asc"
                      :size="300"
                      :nestedField="facet.nestedField"
                      fuzziness="AUTO"
                      searchPlaceholder="Sök..."
                      :showSearch="true"
                      :filterLabel="facet.label"
                      :dataField="facet.field"
                      :react="{ and: getOtherFilterIds(facet.id) }"
                  />

									<data-search
                      v-if="facet.field === 'person_name'"
                      componentId="search"
                      dataField="person_name"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="$t('labels.name')+'..'"
                      :debounce="100"
                      :autosuggest="false"
                      :filterLabel="$t('labels.search')"
                  />

									<data-search
                      v-if="facet.type === 'search' && facet.field !== 'person_name'"
                      :componentId="facet.id"
                      :dataField="facet.field"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="facet.placeholder"
                      :debounce="100"
                      queryFormat="and"
                      :autosuggest="false"
                      :filterLabel="facet.label"
                  />


                  <ReactiveComponent
                    v-if="facet.type === 'custom-datepicker'"
                    :componentId="facet.id"
                    :URLParams="true"
                    :filterLabel="facet.label"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                  >
                    <div slot-scope="{ aggregations, setQuery, value }">
                      <date-picker
                        opens="left"
                        :placeholder="facet.placeholder"
                        :dataField="facet.field"
                        :aggregations="aggregations"
                        :setQuery="setQuery"
                        :selectedValue="value"
                        :show-dropdowns="facet.showDropdowns"
                        :single-date-picker="facet.singleDatePicker"
                      ></date-picker>
                    </div>
                  </ReactiveComponent>

								</div>
							</span>

              <!-- <toggle-button
                  class="filter-action filter-action-xs ml-3 risk-toggle"
                  componentId="riskFilter"
                  :dataField="$field('risk')+'.raw'"
                  :URLParams="true"
                  :data="[{ label: '', value: $i18n.locale == 'en' ? 'Risk' : 'Förhöjd risk' }]"

              /> -->
              <div class="btn btn-grey btn-bold ml-3" @click="toggleFilter" v-show="!showFilter"><i
                  class="fas fa-filter"></i></div>
              <div class="btn btn-success btn-bold ml-3" @click="toggleFilter" v-show="showFilter"><i
                  class="fas fa-filter"></i></div>


            </div>
          </div>
        </div>

        <div class="widget-filter bb-1" v-show="showFilter && enableFilter">
          <div class="row search-filters">
            <div class="col-2 mb-4" v-show="facet.visible" v-for="facet in getFacetsByLevel(2)" :key="facet.id">

              <multi-dropdown-list
                  v-if="facet.type === 'list' && facet.id === 'ageSubFilter'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :size="100"
                  :showSearch="true"
                  :title="facet.label"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :transformData="customOrder"
                  :react="{ and: getOtherFilterIds(facet.id) }"
              />

              <multi-dropdown-list
                  v-else-if="facet.type === 'list'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :size="100"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :showSearch="true"
                  :title="facet.label"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :react="{ and: getOtherFilterIds(facet.id) }"
              />

              <data-search
                  v-if="facet.type === 'search'"
                  :dataField="[facet.field]"
                  iconPosition="right"
                  className="data-search"
                  :showClear="true"
                  :URLParams="true"
                  fuzziness="2"
                  :filterLabel="facet.label"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :debounce="100"
              />

              <ReactiveComponent
                v-if="facet.type === 'custom-datepicker'"
                :componentId="facet.id"
                :URLParams="true"
                :filterLabel="facet.label"
                :react="{ and: getOtherFilterIds(facet.id) }"
              >
                <div slot-scope="{ aggregations, setQuery, value }">
                  <date-picker
                    opens="left"
                    :placeholder="facet.placeholder"
                    :label="facet.label"
                    :dataField="facet.field"
                    :aggregations="aggregations"
                    :setQuery="setQuery"
                    :selectedValue="value"
                    :show-dropdowns="facet.showDropdowns"
                    :single-date-picker="facet.singleDatePicker"
                  ></date-picker>
                </div>
              </ReactiveComponent>

            </div>
          </div>
        </div>
        <div class="widget-body no-padding">
          <SelectedFilters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
        </div>
        <div class="widget-body no-padding">
          <ReactiveComponent componentId="DataControllerSensor" :defaultQuery="customPersonAggregation" :react="{ and: getOtherFilterIds('foo') }"></ReactiveComponent>

          <reactive-list
              @queryChange="getQueryChange"
              @pageChange="pageChange"
              :showResultStats="false"
              componentId="SearchResult"
              :dataField="getSortBy"
              :sortBy="getSortByOrder ? 'desc' : 'asc'"
              className="result-list-container"
              :pagination="true"
              :URLParams="true"
              :from="0"
              :size="15"
              :react="{ and: getOtherFilterIds('foo') }"
            >
            <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data, resultStats }">
              <!-- {{resultStats}} -->

              <thead v-if="data.length !== 0">
              <tr>
                <th
                    width="2%"
                    :class="{ 'sort-up': isSortField('id', true), 'sort-down': isSortField('id', false) }"
                    @click="changeSortBy('id')"
                >
                  #
                </th>
                <th
                    width="3.5%"
                    :class="{ 'sort-up': isSortField('created_at', true), 'sort-down': isSortField('created_at', false) }"
                    @click="changeSortBy('created_at')"
                >
                  Inlagt <info-box :field="'Rubrikfält alla listvyer - skapat'" />
                </th>
                 <th
                    width="3.5%"
                    :class="{ 'sort-up': isSortField('case_date', true), 'sort-down': isSortField('case_date', false) }"
                    @click="changeSortBy('case_date')"
                >
                  Rättegång <info-box :field="'Ärendeformuläret - flik Geografi, rubrik Rättegångsdatum'" />
                </th>
                <th
                    width="3%"
                    :class="{ 'sort-up': isSortField('updated_at', true), 'sort-down': isSortField('updated_at', false) }"
                    @click="changeSortBy('updated_at')"
                >
                  {{ $t('labels.updated') }} <info-box :field="'Rubrikfält alla listvyer - ändrat'" />
                </th>
                <th
                    width="5%"
                    :class="{ 'sort-up': isSortField('court.raw', true), 'sort-down': isSortField('court.raw', false) }"
                    @click="changeSortBy('court.raw')"
                >
                  <!-- {{ $t('labels.priority') }} -->
                  Domstol
                </th>
                <th
                    width="2%"
                    :class="{ 'sort-up': isSortField('testimony_type.raw', true), 'sort-down': isSortField('testimony_type.raw', false) }"
                    @click="changeSortBy('testimony_type.raw')"
                >
                  <!-- {{ $t('labels.status') }} -->
                  Typ av mål
                </th>
                  <th
                      width="5%"
                      :class="{ 'sort-up': isSortField('crime.raw', true), 'sort-down': isSortField('crime.raw', false) }"
                      @click="changeSortBy('crime.raw')"
                  >
                    {{ $t('labels.crime') }}
                </th>

                  <th
                      width="5%"
                      :class="{ 'sort-up': isSortField('testimony_other_type.raw', true), 'sort-down': isSortField('testimony_other_type.raw', false) }"
                      @click="changeSortBy('testimony_other_type.raw')"
                  >
                    Civilmålstyp
                  </th>

                  <th
                      width="7%"
                      :class="{ 'sort-up': isSortField('users', true), 'sort-down': isSortField('users', false) }"
                      @click="changeSortBy('users.name.raw')"
                  >
                    {{ $t('labels.administrator') }}
                  </th>

                  <th
                      width="7%"
                      :class="{ 'sort-up': isSortField('persons_count.raw', true), 'sort-down': isSortField('persons_count.raw', false) }"
                  >
                    Antal personer
                  </th>

              </tr>
              </thead>

              <tbody v-for="item in data" :key="item.id">
              <router-link tag="tr" :to="'/cases/case/' + item.id + '/overview'" class="cursor-pointer">
                <td>{{ item.id }}</td>
                <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td>
                <td>{{ item.case_date ? moment(item.case_date).format("YYYY-MM-DD") : '' }}</td>
                <td>{{ moment(item.updated_at).format("YYYY-MM-DD") }}</td>
                <td>{{ $to(item,'court') }}</td>
                <td>{{ $to(item,'testimony_type') }}</td>
                <td>{{ $to(item, 'crime') }}</td>
                <td>{{ item.testimony_other_type }}</td>
                <td>{{ administrators(item.users) }}</td>
                <td>{{ item.persons ? item.persons.length : 0 }}</td>

              </router-link>
              </tbody>
            </table>

            <template slot="renderNoResults">
              <div class="empty-placeholder pt-5">
                <i class="far fa-times-circle"></i>
                {{ $t('labels.no-results-found') }}
              </div>
            </template>
          </reactive-list>
        </div>
      </div>
    </div>
    <manual />
  </reactive-base>
</template>
<script>
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import DatePicker from '@/components/DatePicker';
import BojAPI from '@/api/boj';
import ExportWitness from '@/components/witness/ExportWitness';
import ExportPeople from '@/components/witness/ExportPeople';
import Manual from '@/components/Manual';
import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  props: {
    title: String,
    defaultQuery: Object,
    stats: {
      default: true,
      type: Boolean,
    },
    enableFilter: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    ExportWitness,
    CustomTicketResults,
    Manual,
    DatePicker,
    InfoBox,
    ExportPeople,
  },

  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      exportEstimate: false,
      exportPeopleModal: false,
      exportPeopleFilter: 'all',
      ticketStatus: [],
      getSortByOrder: true,
      getSortBy: 'updated_at',
      showFilter: false,
      latestQuery: '',
      moment: moment,
      facets: [
        {
          level: 1,
          visible: true,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'courtFilter',
          field: 'court.raw',
          label: this.$t('labels.court'),
          placeholder: this.$t('labels.court'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          id: 'testimonyTypeFilter',
          visible: true,
          field: 'testimony_type.raw',
          label: 'Måltyp',
          placeholder: 'Måltyp',
          small: true,
          // placeholder: this.$t('labels.name')+'..',
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'caseDate',
          field: 'case_date',
          label: 'Rättegångsdatum',
          placeholder: 'Rättegångsdatum',
          type: 'custom-datepicker',
          singleDatePicker: 'range',
          showDropdowns: true,
          small: true,
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'createdDate',
          field: 'created_at',
          label: 'Datum inlagt',
          placeholder: 'Datum inlagt',
          type: 'custom-datepicker',
          singleDatePicker: 'range',
          showDropdowns: true,
          small: true,
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'userSubFilter',
          field: 'persons.user.keyword',
          nestedField: 'persons',
          label: this.$t('labels.administrator'),
          placeholder: this.$t('labels.administrator'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          id: 'searchID',
          visible: true,
          field: 'id_searchable',
          label: this.$t('labels.case')+'#',
          placeholder: this.$t('labels.case')+'#..',
          type: 'search',
          permission: null,
        },
        {
          level: 2,
          id: 'crimeSubFilter',
          visible: true,
          field: 'crime.raw',
          label: this.$t('labels.crime'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'civilSubFilter',
          visible: true,
          field: 'testimony_other_type.raw',
          label: 'Civilmålstyp',
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'externalCaseSubFilter',
          visible: true,
          field: 'external_case.raw',
          label: 'Externt mål',
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'testimonySecurityRoomFilter',
          visible: true,
          field: 'testimony_security_room.raw',
          label: 'Säkerhetssal',
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'specialCircumstanceFilter',
          field: this.$field('crime_relation')+'.raw',
          label: this.$t('labels.special-circumstances'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'specialEventSubFilter',
          field: this.$field('special_events')+'.raw',
          label: this.$t('labels.special-events'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'addedBySubFilter',
          field: 'testimony_users.name.keyword',
          label: this.$t('labels.created-by'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        }
      ],
    };
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder === 'true';
    }
  },

  computed: {
    visibleTicketStatus: function () {
      return this.$store.getters.getTicketStatuses;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    administrators(users) {
      return users ? users.map(user => (user.name)).join(', ') : ''
    },
    customPersonAggregation() {
      return {
        runtime_mappings: {
          external_case_without_no: {
            type: 'keyword',
            script: 'if (doc[\'external_case.raw\'].size() != 0 && doc[\'external_case.raw\'].value != \'Nej\') emit(\'hot\')'
          }
        },
        aggs: {
          persons: {
            nested: {
              path: 'persons'
            },
            aggs: {
              name: {
                terms: {
                  field: 'persons.user.keyword',
                  size: 100,
                  order: {
                    _key: 'desc'
                  }
                }
              }
            }
          },
          cases: {
            value_count: {
              field: 'id'
            }
          },
          crimes: {
            value_count: {
              field: 'crime.raw'
            }
          },
          civilcases: {
            value_count: {
              field: 'testimony_other_type.raw'
            }
          },
          external_case: {
            value_count: {
              field: 'external_case_without_no'
            }
          }

        }
      }
    },
    customOrder(data) {
      let customAge = [
        'Barn 0-11',
        'Ungdom 12-17',
        'Ung vuxen 18-25',
        'Vuxen 26-64',
        'Äldre 65 +',
        'Uppgift saknas'
      ];

      let newData = data.slice().sort(function (a, b) {
        return customAge.indexOf(a.key) - customAge.indexOf(b.key);
      });

      return newData;
    },
    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

    /**
     * Toggle Export modal.
     */
    closeExportWitness() {
      this.exportEstimate = null;
    },

    /**
     * Store any changes to the Elasticsearch query
     */
    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },

    exportPeople(filter) {
      this.exportPeopleModal = true;
      this.exportPeopleFilter = filter;
    },

    closeExportPeople() {
      this.exportPeopleModal = null;
    },

    /**
     * Retrieve an estimate before actually exporting tickets.
     */
    exportResultsEstimate(template) {
      BojAPI.exportTestimonyFromElasticEstimate(this.latestQuery).then((response) => {
        this.exportEstimate = response.data;
      });
    },

    /**
     * Begin export of tickets.
     */
    exportResults(template) {
      this.exportEstimate = null;
      BojAPI.exportTestimonyFromElastic(this.latestQuery).then((response) => {
      });
    },

    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },

    /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      ids.push('createdDate');
      ids.push('caseDate');
      ids.push('updateAtDate');
      // console.log("🚀 ~ file: WitnessList.vue ~ line 634 ~ getOtherFilterIds ~ ids", ids)

      return ids;
    },

    /**
     * Page Change Hook.
     *
     * Added to avoid scrolling of page when navigating through
     * the pages using the reactivesearch pagination.
     *
     */
    pageChange() {
    },

    /**
     * Return values from elasticsearch aggregations.
     *
     * @param searchState
     * @param componentId
     * @param aggregationId
     * @param key
     * @returns {string|number}
     */
    getBucketCount(searchState, componentId, aggregationId, key) {
      if (searchState[componentId] === undefined) {
        return
      }

      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];

        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);

          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else if (Object.prototype.hasOwnProperty.call(aggregation, 'doc_count')) {
          let buckets = searchState[componentId].aggregations[aggregationId].doc_count.toLocaleString('sv-SE');
          let new_buckets = [];

          if (aggregationId == 'persons') {
            if (undefined !== searchState.userSubFilter.value) {
              new_buckets = searchState[componentId].aggregations[aggregationId].name.buckets.filter(e => searchState.userSubFilter.value.indexOf(e.key) != -1);
            }
          }

          const initialValue = 0;
          const selectedPersonCount = new_buckets.reduce(
            (previousValue, currentValue) => previousValue + currentValue.doc_count,
            initialValue
          );

          if (buckets && selectedPersonCount) {
            return selectedPersonCount.toLocaleString('sv-SE');
          } else {
            return buckets;
          }

        } else if (Object.prototype.hasOwnProperty.call(aggregation, 'value')) {
          let buckets = searchState[componentId].aggregations[aggregationId].value.toLocaleString('sv-SE');

          if (buckets) {
            return buckets;
          } else {
            return 0;
          }

        } else {
          return 0;
        }
      } else if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'hits')) {
        let hits = searchState[componentId].hits;

        if (Object.prototype.hasOwnProperty.call(hits, 'total')) {
          return searchState[componentId].hits.total.toLocaleString('sv-SE');
        } else {
          return 0;
        }
      }
      return 0;
    },

    /**
     * Determine if a field is currently used for sorting.
     *
     * @param field
     * @param direction
     * @returns {boolean}
     */
    isSortField(field, direction) {
      return this.getSortBy === field && this.getSortByOrder === direction;
    },

    /**
     * Toogle extra fields
     */
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    /**
     * Change sort attribute for the results.
     *
     * @param value
     */
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;

      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    /**
     * Updates the query string paramter.
     *
     * Currently used for adding / replacing the current sort.
     *
     * @param key
     * @param value
     */
    updateQueryStringParam(key, value) {
      const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
      const urlQueryString = document.location.search;
      const newParam = key + '=' + value;
      let params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        const keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it - otherwise, add it to end of query string
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
  },
};
</script>
